<template>
  <v-container>
    <v-row>
      <v-col md="6" v-for="number in 4" :key="'loader-' + number">
        
          <v-skeleton-loader
            class="mx-auto"
            type="card"
            height="150"
          ></v-skeleton-loader>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "loadingApps",

  data() {
    return {
      loading: false,
    };
  },
};
</script>
