import {
    getServerRequest,
    postServerRequest
} from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;


export async function getUserInfoServer() {
    const token = localStorage.getItem("sg-token");
    const config = { headers: { token } };
    const url = `${httpURL}/master/userInfo`;
    return await getServerRequest(url, config);
}

export async function getOficiosAppsUser(codigo, token) {
    const oficiosURL = store.getters.oficiosURL;
    const config = { headers: { token } };
    const url = `${oficiosURL}/login/appUser`;
    return await postServerRequest(url, { codigo }, config);
}

export async function getPresupuestoAppsUser(codigo, token) {
    const presupuestoURL = store.getters.presupuestoURL;
    const config = { headers: { token } };
    const url = `${presupuestoURL}/login/appUser`;
    return await postServerRequest(url, { codigo }, config);
}

export async function getSesionesAppsUser(codigo, token) {
    const sesionesURL = store.getters.sesionesURL;
    const config = { headers: { token } };
    const url = `${sesionesURL}/login/appUser`;
    return await postServerRequest(url, { codigo }, config);
}

export async function getEleccionesAppsUser(codigo, token) {
    const eleccionesURL = store.getters.eleccionesURL;
    const config = { headers: { token } };
    const url = `${eleccionesURL}/login/appUser`;
    return await postServerRequest(url, { codigo }, config);
}

export function getBecasAppsUser() {
    return new Promise((resolve) => setTimeout(function () {
        resolve({ ok: false })
    }, 1000));
}

export async function getAdminAppsUser(codigo, token) {
    const config = { headers: { token } };
    const url = `${httpURL}/login/appUser`;
    return await postServerRequest(url, {codigo}, config);
}

export async function getRedirectionToken(codigo, appApi) {
    const apiURL = store.getters[`${appApi}URL`];
    const appRedirect = store.getters[`${appApi}Redirect`];
    const config = { headers: { } };
    const url = `${apiURL}/login/token`;
    let response = await postServerRequest(url, { codigo }, config);
    response.redirect = appRedirect;
    console.log(response);
    return response;
}