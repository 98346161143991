<template>
  <v-app id="inspire">
    <notifications group="gral_notif" position="bottom right" :width="400" />
    <v-main>
      <v-container fluid fill-height class="fondoApp">
        <v-row justify="center">
          <v-col md="8">
            <v-card outlined :loading="loading" color="blue-grey lighten-4">
              <v-img height="100px" src="@/assets/BANNER-2.png"></v-img>
              <v-toolbar dark flat dense color="#86212b">
                <v-spacer></v-spacer>
                <h3>Secretaría General</h3>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-toolbar dark flat dense color="#86212b">
                {{ userName }}
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      dark
                      @click="cerrarSesion()"
                    >
                      <v-icon>mdi-power</v-icon>
                    </v-btn>
                  </template>
                  <span>Cerrar sesión</span>
                </v-tooltip>
              </v-toolbar>
              <loadingAppsComponent v-if="loading" />
              <appsComponent
                v-else
                :apps="apps"
                :codigoUsuario="codigoUsuario"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getUserInfoServer,
  getOficiosAppsUser,
  getPresupuestoAppsUser,
  getSesionesAppsUser,
  getEleccionesAppsUser,
  getBecasAppsUser,
  getAdminAppsUser,
} from "./apps.service";
import appsList from "./apps.json";

import loadingAppsComponent from "./loadingApps";
import appsComponent from "./apps";

export default {
  name: "appsView",
  components: { loadingAppsComponent, appsComponent },
  computed: {
    ...mapGetters(["sessionToken", "userName", "codigoUsuario"]),
  },
  data() {
    return {
      loading: false,
      apps: [],
    };
  },
  async created() {
    const token = localStorage.getItem("sg-token");
    this.$store.dispatch("setSessionToken", token);
    if (!token) this.$router.push("/login");
    if (!this.userName || !this.codigoUsuario) {
      await this.getUserInfo();
    }
    this.getApps();
  },
  methods: {
    async getUserInfo() {
      try {
        const serverResponse = await getUserInfoServer();
        this.$store.dispatch("setUserName", serverResponse.userName);
        this.$store.dispatch("setCodigoUsuario", serverResponse.codigo);
      } catch (error) {
        console.error(error);
      }
    },
    async getApps() {
      try {
        this.loading = true;
        const promises = [
          getSesionesAppsUser(this.codigoUsuario, this.sessionToken),
          getEleccionesAppsUser(this.codigoUsuario, this.sessionToken),
          getBecasAppsUser(),
          getAdminAppsUser(this.codigoUsuario, this.sessionToken),
          getOficiosAppsUser(this.codigoUsuario, this.sessionToken),
          getPresupuestoAppsUser(this.codigoUsuario, this.sessionToken),
        ];
        const serverResponse = await Promise.allSettled(promises);
        this.mostrarApps(serverResponse);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.cerrarSesion();
      }
    },
    mostrarApps(promisesResult) {
      promisesResult.forEach((result, index) => {
        if (this.validResponse(result)) {
          this.agregarApp(index);
        }
      });
      this.verificarApps();

    },
    validResponse(response) {
      if (response.status == "rejected") return false;
      if (!response.value.appUser) return false;
      else return response.value.ok;
    },
    agregarApp(index) {
      if (index == 0) this.apps.push(appsList.sesionesCGU);
      if (index == 1) this.apps.push(appsList.eleccionesCGU);
      if (index == 2) this.apps.push(appsList.condonaciones);
      if (index == 3) this.apps.push(appsList.admin);
      if (index == 4) this.apps.push(appsList.oficios);
      if (index == 5) this.apps.push(appsList.presupuesto);
    },
    cerrarSesion() {
      localStorage.removeItem("sg-token");
      this.$store.dispatch("setSessionToken", null);
      this.$router.push("/login");
    },
    verificarApps(){
      if(!this.apps.length) {
        localStorage.removeItem("sg-token");
        this.$store.dispatch("setSessionToken", null);
        this.$router.push("/login");
      }
    }
  },
};
</script>

<style>
.fondoApp {
  background: #b0bec5;
}
</style>
