<template>
  <v-container>
    <v-row>
      <v-col md="6" v-for="(app, index) in appsLocal" :key="'app-' + index">
        <v-card flat :color="app.color">
          <a href="javascript:void(0)" @click="ingresarApp(app)">
            <v-img height="150" contain :src="app.imagen"></v-img>
          </a>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRedirectionToken } from "./apps.service";
export default {
  name: "appsComponent",
  props: ["apps", "codigoUsuario"],
  computed: {
    appsLocal() {
      return this.apps.map((x) => {
        let app = { ...x };
        app.imagen = require(`../../assets/main-menu/${x.image}`);
        return app;
      });
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async ingresarApp(app) {
      if (app.remote) {
        try {
          this.loading = true;
          const serverResponse = await getRedirectionToken(
            this.codigoUsuario,
            app.api
          );
          this.loading = false;
          const redirection = `${serverResponse.redirect}/redirect/${serverResponse.token}`;
          window.open(redirection, "_self");
        } catch (error) {
          this.loading = false;
          this.$notificarError(error);
        }
      } else this.$router.push(app.route);
    },
  },
};
</script>
